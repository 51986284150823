import "./App.css";
import React, { useEffect, useState } from "react";
// import { listNotes } from "./graphql/queries";
// import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
// import config from "./aws-exports";
import axios from "axios";

function App() {
  const [headText, setHeadText] = useState(
    "Please enter meeting details to join"
  );
  const [url, setUrl] = useState();
  const [tokenInput, setTokenInput] = useState();
  const [id, setId] = useState("");
  const [vid, setVid] = useState();
  const [vidurl, setVidurl] = useState();
  const [resData, setResData] = useState();
  const [start, setStart] = useState(true);
  const [parag, setParag] = useState("");
  var data_len = 0;

  const today = new Date();
  // console.log(today)
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  const dateTime = date + "_" + time;

  const token = "Token" + " " + String(tokenInput);

  // useEffect(()=>{
  // })

  // const myInit = {
  //   headers: {}, // OPTIONAL
  //   response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  //   body:{'data': 0}
  // };

  // const localApi = async () =>{
  //   API.post('realtimeapi', '/item/', myInit).then((data)=>{
  //     console.log(data)
  //   })
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStart(true);
    setHeadText("Bot joining the call, please do not close this window");
    document.querySelector(".btn-success").style.display = "none";
    document.querySelector(".prev-meeting").style.display = "none";
    document.querySelector(".current-meeting").style.display = "none";
    document.querySelector(".btn-danger").style.display = "block";
    console.log({
      meeting_url: url,
      token: tokenInput,
    });
    await axios
      .post("https://dev.api.sproutsai.com/zoombot/join", {
        meeting_url: url,
        token: tokenInput,
      })
      .then((res) => {
        console.log(res.data.data);
        setVid(res.data.data.id);
    setStart(true);
    getLiveTranscription(res.data.data.id, 25000);
      });
  };

  function getLiveTranscription(id, delay) {
    setTimeout(async function () {
      //your code to be executed after 12 second
      document.querySelector(".current-meeting").style.display = "block";
      var p = document.createElement("p");
      await axios
        .get("https://dev.api.sproutsai.com/zoombot/live/" + String(id))
        .then((response) => {
          console.log(response.data.data);
          if (response.data) {
            var data_arr = response.data.data;
            var sentence = "";
            var para = "";
            data_arr = data_arr.sort(function (a, b) {
              return a.Start - b.Start;
            });

            if (data_arr.length > data_len) {
              for (var i = data_len; i < data_arr.length; i++) {
                para =
                  String(data_arr[i].data.data.transcript.speaker) +
                  "[" +
                  String(data_arr[i].Start) +
                  "] : ";
                console.log(data_arr[i].data.data.transcript.words);
                sentence = data_arr[i].data.data.transcript.words
                  .map((word) => word.text)
                  .join(" ");
                para = para + sentence;
                console.log(i, sentence, para);
                p.innerText = para;
                document.querySelector(".transcript").append(p);
              }
              // setParag(para);

              data_len = data_arr.length;
            }
          }
        });
      if (start) {
        getLiveTranscription(id, 3000);
      }
    }, delay);
  }
  // async function uploadData(e) {
  //   try {
  //     //  set the filename resumeFile to trace the file
  //     const result = await Storage.put(
  //       dateTime + "_" + vid + ".json",
  //       resData,
  //       {
  //         // + resData.meeting_metadata.title.split(' ')[0]
  //         level: "public",
  //         progressCallback(progress) {
  //           console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
  //         },
  //       }
  //     );
  //     // console.log(result);
  //     // setResume(() => true);
  //   } catch (error) {
  //     console.log("Error uploading file: ", error);
  //   }
  // }

  const LeaveMeeting = async (e) => {
    const api = "https://api.recall.ai/api/v1/bot/" + vid + "/leave_call";
    await fetch(api, {
      method: "POST",
      headers: { Authorization: token },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          console.log(res.data);
          setResData(res.data);
          // uploadData();
        })
    );
  };

  const endMeeting = async (e) => {
    e.preventDefault();
    // LeaveMeeting()
    setStart(false);
    console.log(vid);
    setHeadText("Please enter meeting details to join");
    document.querySelector(".current-meeting").style.display = "none";
    document.querySelector(".btn-danger").style.display = "none";
    document.querySelector(".btn-success").style.display = "block";
    try{
    await axios
      .get("https://dev.api.sproutsai.com/zoombot/details/" + String(vid), {
        headers: { token: tokenInput },
      })
      .then((res) => {
        console.log(res);
      });}
      catch(err){
        console.log(err)
      }
    await axios
      .post(
        " https://dev.api.sproutsai.com/zoombot/leave/" + String(vid),
        null,
        { headers: { token: tokenInput } }
      )
      .then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then(async (res) => {
            console.log(res);
            if (res.data.video_url) {
              setVidurl(res.data.video_url);
              console.log(res.data.video_url);
              document.querySelector(".prev-meeting").style.display = "block";
            }
            const vidapi =
              "https://dev.api.sproutsai.com/zoombot/transcription/" +
              String(vid);
            await axios
              .get(vidapi, null, {
                headers: {
                  token: tokenInput,
                }
              })
              .then((response) =>
                response
                  .json()
                  .then((data) => ({
                    data: data,
                    status: response.status,
                  }))
                  .then((res) => {
                    console.log(res);
                    showTranscribedData(res.data);
                  })
              );
            setResData(res.data);
            // uploadData();
          })
      );

    // console.log(response.json());
    //  var varid = response.json()
    // console.log(varid.Object)
    // setId(response.id)
    // })
    // .catch((error) => {
    //   console.error(error);
    // });
  };

  function showTranscribedData(data) {
    for (var i = 0; i < data.length; i++) {
      var p = document.createElement("p");
      console.log(data[i].words);
      var words = data[i].words;
      var sentence = data[i].speaker + ":- ";
      for (var word = 0; word < words.length; word++) {
        sentence += words[word].text + " ";
      }
      console.log(sentence);
      p.innerText = sentence;
      document.querySelector(".prev-meeting").append(p);
    }
  }

  return (
    <div className="App">
      <header className="App-header"></header>
      <main>
        <div className="main-section">
          <h1>{headText}</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="url"
              value={url}
              required
              onChange={(event) => {
                setUrl(() => event.target.value);
              }}
              placeholder="Enter Meeting URL"
            />
            <input
              type="text"
              name="token"
              value={tokenInput}
              required
              onChange={(event) => {
                setTokenInput(() => event.target.value);
              }}
              placeholder="Enter Token Id"
            />
            <button type="submit" className="btn  btn-lg btn-success">
              {" "}
              Submit{" "}
            </button>
            <button onClick={endMeeting} className="btn btn-lg btn-danger">
              {" "}
              Exit{" "}
            </button>
            <div className="current-meeting">
              <h2>Live Transcription</h2>
              <div className="transcript"></div>
            </div>
            <div className="prev-meeting">
              <h2>
                Previous Meeting Recording URL - <br />{" "}
                <a href={vidurl}>{vidurl}</a>
              </h2>
              <h3>Transcripted data - </h3>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

export default App;
